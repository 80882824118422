<template>
  <div>
    <div id="content">
      <b-container class="routeCssSearch">
        <!-- <Route /> -->

      </b-container>

      <b-container>
      <div class="keyblock">
        <span class="keyText">Key:</span> 
        <b-row class="keySec">
          <b-col class="attached">
            Documents
          </b-col>
          <b-col class="post">
            Posts
          </b-col>
          <b-col class="page">
            Pages
          </b-col>
          <b-col class="events">
            Events
          </b-col>
          <b-col class="disciplines">
            Sports
          </b-col>
          <b-col class="jobs">
            Jobs
          </b-col>
          <b-col class="profiles">
            Profiles
          </b-col>
        </b-row>
      </div>
      </b-container>
      <div class="newSearch">
        <b-container>
          <b-jumbotron :header="'Search \'' + filter + '\''">
          </b-jumbotron>
        </b-container>
      </div>
      <b-container>
        <div v-if="this.loading === true">
          <b-row v-for="(search, index) in allposts"  v-show="search.subtype !== 'profiles' && search.subtype !== 'meet-the-team' && search.subtype !== 'hall-of-fame'" :key="index" style="margin-bottom:20px">
            <b-col v-if="search.subtype !== 'profiles' && search.subtype !== 'meet-the-team' && search.subtype !== 'hall-of-fame'">
              <a :href="search.url.replace(/home/g, 'our-sports')" class="nounderline">
                <b-card class="cardhover" :class='{post: search.subtype === "post", page: search.subtype === "page", events: search.subtype === "events", attached: search.subtype === "pdfs", profiles: search.subtype === "profiles", jobs: search.subtype === "jobs", disciplines: search.subtype === "disciplines" }'>
                  <b-card-text v-html="search.title">
                  </b-card-text>
                </b-card>
              </a>
            </b-col>
          </b-row>
          <b-row v-for="(search, index) in profilePost" :key="index" style="margin-bottom:20px">
            <b-col>
              <a :href="'https://scot-swim.ocs-sport.com/our-sports/' + search.acf.discipline + '/profiles/' + search.slug" class="nounderline">
                <b-card class="cardhover" :class='{ profiles: search.type === "profiles"}'>
                  <b-card-text v-html="search.title.rendered">
                  </b-card-text>
                </b-card>
              </a>
            </b-col>
          </b-row>
          <b-row v-for="(search, index) in media" :key="index" style="margin-bottom:20px">
            <b-col>
              <a :href="search.link" class="nounderline">
                <b-card class="cardhover" :class='{ attached: search.type === "attachment"}'>
                  <b-card-text v-html="search.title.rendered">
                  </b-card-text>
                </b-card>
              </a>
            </b-col>
          </b-row>
          <b-row v-for="(search, index) in halloffamePost" :key="index" style="margin-bottom:20px">
            <b-col>
              <a :href="'https://scot-swim.ocs-sport.com/about-us/' + 'hall-of-fame/' + search.slug" class="nounderline">
                <b-card class="cardhover" :class='{ profiles: search.type === "hall-of-fame"}'>
                  <b-card-text v-html="search.title.rendered">
                  </b-card-text>
                </b-card>
              </a>
            </b-col>
          </b-row>
          <b-row v-for="(search, index) in meettheteamPost" :key="index" style="margin-bottom:20px">
            <b-col>
              <a :href="'https://scot-swim.ocs-sport.com/about-us/' + 'meet-the-team/' + search.slug" class="nounderline">
                <b-card class="cardhover" :class='{ profiles: search.type === "meet-the-team"}'>
                  <b-card-text v-html="search.title.rendered">
                  </b-card-text>
                </b-card>
              </a>
            </b-col>
          </b-row>
        </div>
        <div v-else>
          <div class="loaderBox">
            <div class="loader"></div>
            <h2 class="loaderText">Loading...</h2>
          </div>
        </div>
      </b-container>
    </div>   
    <Footer /> 
  </div>

</template>

<script>
import apiCall from "@/services/api.js";
// import Route from "@/components/route.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "search",
  components: { Footer },
  data() {
    return {
      allposts: [],
      profilePost: [],
      media: [],
      halloffamePost: [],
      meettheteamPost: [],
      filter: this.$route.query.search,
      fields: [{ key: "better_featured_image", label: "" }],
      filterOn: [],
      text:'',
      loading: '',
      title: '',
      description: ''
    };
  },
  metaInfo() {
    return {
      title: "Search",
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: this.description },
      ]
    }
  },
  // created() {
  //   const json = "/wp/v2/search?search=" + this.filter + "&per_page=100";
  //   apiCall
  //     .wpInfo(json)
  //     .then(({ data }) => {
  //       this.allposts = data;
  //       this.loading = false;
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       throw error;
  //     });
  // },

  methods: {
    routeTitle(item) {
      var low = item;
      var rep = low.replace(/-/g, " ");
      return rep;
    },
    async Dis() {
      try {
        this.loading = false;
        // First call, await for call to return
        let call = await apiCall.wpInfo("/wp/v2/media?search=" + this.filter + "&per_page=5" + "&orderby=date");
        this.media = call.data;
        // Second call, await for call to return
        // First call, await for call to return
        let first_call = await apiCall.wpInfo("/wp/v2/search?search=" + this.filter + "&per_page=5" + "&orderby=date" );
        this.allposts = first_call.data;
        // Second call, await for call to return
        let second_call = await apiCall.wpInfo("/wp/v2/profiles?search=" + this.filter + "&orderby=date");
        this.profilePost = second_call.data;
        let third_call = await apiCall.wpInfo("/wp/v2/hall-of-fame?search=" + this.filter + "&orderby=date");
        this.halloffamePost = third_call.data;
        let fourth_call = await apiCall.wpInfo("/wp/v2/meet-the-team?search=" + this.filter + "&orderby=date");
        this.meettheteamPost = fourth_call.data;
        this.loading = true;
      } catch (error) {
        console.log("error in await calls");
        console.log(error);
        throw error;
      }
    },
  },
  mounted() {
    this.Dis();
  },
};
</script>

<style scoped>
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #201546;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  margin: auto;
  position: initial;
  right: 0;
  left: 0;
}
.loaderText {
  text-align: center;
}
h2.loaderText {
  margin-top: 20px;
}
/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.keyblock {
  margin-top: 30px;
}
span.keyText {
  font-weight: bold;
}
.keySec {
  padding-left: 15px;
  padding-right: 15px;
}
a.nounderline {
  text-decoration: none!important;
}
.cardhover:hover {
  background-color: white;
  color: #000!important;
  font-weight: bold;
  text-decoration: none;
}
.cardhover {
  background-color: #db044e;
  color: #fff!important;
  font-weight: bold;
  text-decoration: none;
}

.post {
  background-color: #009fe3;
  color: #fff!important;
  font-weight: bold;
  text-decoration: none;
}
.page {
  background-color: #201546;
  color: #fff!important;
  font-weight: bold;
  text-decoration: none;
}
.events {
  background-color: rgb(175, 202, 11);
  color: #fff!important;
  font-weight: bold;
  text-decoration: none;
}
.pdfs {
  background-color: rgb(110, 201, 223);
  color: #fff!important;
  font-weight: bold;
  text-decoration: none;
}
.disciplines {
  background-color: rgb(92, 67, 156);
  color: #fff!important;
  font-weight: bold;
  text-decoration: none;
}
.jobs {
  background-color: rgb(52, 153, 128);
  color: #fff!important;
  font-weight: bold;
  text-decoration: none;
}
.profiles {
  background-color: rgb(248, 2, 64);
  color: #fff!important;
  font-weight: bold;
  text-decoration: none;
}
.attached {
  background-color: rgb(248, 141, 2);
  color: #fff!important;
  font-weight: bold;
  text-decoration: none;
}
.routeCssSearch {
  margin-top: 0;
  padding-top: 0px;
  display: inline-block;
}
::v-deep .b-tableClass > tbody > tr > td {
  border-top: 0px solid #dee2e6!important;
}
::v-deep a.nav-link {
  max-width: 360px;
}
::v-deep .table th, .table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 0px solid #dee2e6!important;
}
::v-deep thead {
  display: none;
}
::v-deep tr {
  display: inline-block;
}
.searchResultItem {
  padding-bottom: 25px;
}
a.searchLink {
  text-decoration: none;
}
h2.resultsTitle {
  font-size: 12pt;
  background: #1f1545;
  width: 100%;
  color: #fff;
  padding: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
h2.resultsTitle:hover {
  font-size: 12pt;
  background: #009fe3;
  width: 100%;
  color: #fff;
  padding: 20px;
}
img.featuredImage {
  /* height: 150px;
  width: 250px; */
  width: 100%;
  max-height: 219px;
}
#content {
  padding-left: 20%;
}
h2 {
  font-size: 30px;
}
::v-deep p {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 14px !important;
}
::v-deep .jumbotron {
  padding-left: 0;
  background-color: transparent;
  padding-bottom: 0em;
}
li {
  list-style-type: none;
}
.nav-link {
  padding: 0.5em 0;
}
::v-deep table {
  margin-top: 75px;
}
.newSearch {
  /* margin-top: 75px; */
}
::v-deep .display-3 {
  font-size: 30px;
  font-weight: 500;
}
.seachInput {
  width: 25%;
  display: inline-block;
  margin-top: 1em;
}
.sIcon {
  color: white !important;
  background-color: #263056;
  padding: 5px 11px;
  border-radius: 5px;
}
@media only screen and (max-width: 990px) { 
  #content {
    padding-left: 0px;
  }
  img.featuredImage {
    width: 100%;
    max-height: inherit;
  }
  a.nav-link {
    max-width: 100%;
  }
}

@media only screen and (max-width: 425px) { 
  a.nav-link {
    max-width: 74%!important;
  }
  ::v-deep div#nav-collapse___BV_modal_content_ {
    max-width: 100%!important;
  }
  ::v-deep div#nav-collapse {
    max-width: 100%!important;
  }
}
</style>
